import React from "react"
import {Link} from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import "../../scss/products.scss"
import "../../scss/youchat.scss"

import SEO from "../../components/seo"
import HeaderLogoImg from "../../assets/img/compsol/chat_1.svg"
import HeaderImg from "../../assets/img/youEngage/Img1.png"
import Icon1 from "../../assets/img/youEngage/Icon1.png"
import Icon2 from "../../assets/img/youEngage/Icon2.png"
import Icon3 from "../../assets/img/youEngage/Icon3.png"
import Icon5 from "../../assets/img/youEngage/Icon5.png"
import Icon6 from "../../assets/img/youEngage/Icon6.png"
import Icon7 from "../../assets/img/youEngage/Icon7.png"
import Icon8 from "../../assets/img/youEngage/Icon8.png"
import Icon9 from "../../assets/img/youEngage/Icon9.png"
import Icon10 from "../../assets/img/youEngage/Icon10.png"
import Icon11 from "../../assets/img/youEngage/Icon11.png"
import Icon12 from "../../assets/img/youEngage/Icon12.png"
import GroupImg1 from "../../assets/img/youEngage/Img2.png"
import GroupImg2 from "../../assets/img/youEngage/Img3.png"
import TickIcon from "../../assets/img/youEngage/tick.svg"
import SingleForm from "../../components/singleForm"

const YouchatPage = () => {
  return (
    <div>
      <SEO title="Youchat" keywords={[`youpal`, `youpal group`, `youchat`]} />
      <div className="youEngageMain products">
        <section className="container">
          <div className="row">

            <div className="col-12 order-1 order-md-1">
              <HeaderLogoImg className="headerLogo" />
              <div className="internalMainHeader bugP">
                <h2 className="internalNarrowCol">
                It all stays in the chat
                </h2>
              </div>
            </div>

            <div className="col-12 order-3 order-md-2">
              <p className="internalNarrowCol">
              Youchat is an omnichannel platform that will centralise all your communication. 
              <br />
              Engage with customers or colleagues… or stay in touch with loved ones.
              <br />
              Youchat is a fully customisable platform which merges communication and workstream collaboration in one platform.
              </p>
            </div>

            <div className="col-12 order-4 order-md-3">
              <Link to="/products/request-demo">
              <button type="button" className="btn cta btn-primary px-5 mt-5 productButton d-block mx-auto">
                <span>Free demo</span>
              </button>
              </Link>
            </div>

            <div className="col-12 order-2 order-md-4">
              <img className="headerImg" src={HeaderImg} />
            </div>

          </div>
        </section>

        <section className="container solutionsSection">
          <div className="col">
            <span className="internalNarrowCol internalSmallHeader">
              Solutions
            </span>
            <h3 className="internalNarrowCol">
            Our solution for your business
            </h3>
            <p className="internalNarrowCol">
            Finding the right communication tool is always important for any business. A good communication channel is essential for efficiency and successful cooperation.
            </p>
          </div>
        </section>

        <section className="container contactsSection">
          <div className="row">
            <div className="col">
              <div>
                <img src={Icon1} />
              </div>
              <h4>Comunication wire</h4>
              <p>
              Youchat is channel adapted to everyone’s individual needs.
</p>
            </div>
            <div className="col">
              <div>
                <img src={Icon2} />
              </div>
              <h4>Ultimacy</h4>
              <p>
              Get a suite of communication applications on one platform.
              </p>
            </div>
            <div className="col">
              <div>
                <img src={Icon3} />
              </div>
              <h4>It all stays with you</h4>
              <p>
              We value your privacy. Your data is safe with us. We also provide full support and customisation of the app.
</p>
            </div>
          </div>
        </section>

        <section className="container internalSection analyticsSection">
          <div className="row TwoCol">
            <div className="col">
              <img className="sideImg" src={GroupImg1} />
            </div>
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader text-left">
                ANALYTICS
                </div>
                <h3 className="text-left">We make email or chat possible in one place.</h3>
                <p>
                Create teams just by adding anyone with @symbol.
</p>
                <div className="row">
                    <div className="col">
                        <div className="squareHolder"><img src={Icon5} /></div>
                        <h4>Time Efficient</h4>
                        <p>
                        Rapidly upload and download media or other files. Look for the information you need and find it in an instant. </p>
                    </div>
                    <div className="col">
                        <div className="squareHolder"><img src={Icon6} /></div>
                        <h4>Youchat Channel</h4>
                        <p>
                        We have integrated translation features that can synchronically translate from multiple languages.
                         </p>
                    </div>
                </div>
                <div className="buttonArea">
                <Link to="/products/request-demo">
                  <button type="button" className="btn btn-primary px-4">
                    <span>Try it out free</span>
                    <FaArrowRight style={{ fill: "white" }} />
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container exploreSection">
          <div className="row">
            <div className="col">
              <div className="internalSmallSecondaryHeader">FEATURES</div>
              <h3>Explore our awesome features</h3>
              <p>Here are some of the cool features of Youchat

</p>
              <div className="row mt-0 mt-md-5">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon7} />
                    </div>
                    <div className="col sideContent">
                      <h4>Chat</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon8} />
                    </div>
                    <div className="col sideContent">
                      <h4>Video Conference</h4>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon9} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Searching tools</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon10} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4> Creating a team </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon11} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>Individual notifications</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="exploreFeature">
                  <div className="row">
                    <div>
                      <img src={Icon12} />
                    </div>
                    <div className="col">
                      <div className="col sideContent">
                        <h4>File or image upload</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container internalSection collaborateSection">
          <div className="row TwoCol">
            <div className="col">
              <div className="col sideContent">
                <div className="internalSmallSecondaryHeader">COLLABORATE</div>
                <h3>Trusted and Secure</h3>
                <p className="mt-0 mt-md-5">
                Security and Privacy are our most valuable and important features. Chat without fear of leakage of sensitive information.
                </p>
                <p>A safe workplace means:
                </p>
                <div className="row collaborationList">
                  <div className="col-6">
                    <img src={TickIcon} />
                    Transparency
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Username restrictions
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Self-managing installs
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Security
                  </div>
                  <div className="col-6">
                    <img src={TickIcon} />
                    Admin moderation
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <img className="sideImg" src={GroupImg2} />
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row contactBox">
            <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=22" selector="contact-us-mautic-product-form" />
          </div>
        </section>
      </div>
    </div>
  )
}

export default YouchatPage
